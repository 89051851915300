#homepage-banner {
  @media (min-width:767px) {
    background-image: url('/static/img/banners/' + $bannerimage);
    background-repeat: no-repeat;
    background-size: contain;
    margin-top: -10px;
  }

  #homepage-topbox {
    background: $primary-color;
    padding: 10px 30px;
    margin-bottom: 20px;

    @media (min-width:767px) {
      border-radius: 20px;
      margin-top: 300px;
    }

    display: flex;
    flex-direction: row;
    justify-content: center;

    .col-12 {
      display: flex;
      flex-direction: column;
      justify-content: center;

      @media all and (-ms-high-contrast:active), all and (-ms-high-contrast:none) {
        display: block !important;
      }

      @supports (-ms-accelerator:true) {
        display: block !important;
      }
    }

    .row {
      width: 100%;
    }

    #topbox-booking-widget {
      h3,
      p {
        color: #fff;
        text-align: center;
      }

      iframe {
        min-height: 150px;
      }
    }

    #topbox-youtube {
      iframe,
      video {
        border-radius: 25px;
        height: 280px;
      }
    }
  }

  #facilityiconcontainer {
    margin-top: 10px;

    @media (max-width:767px) {
      display: none;
    }

    display: flex;
    flex-direction: row;
    justify-content: center;

    .col-12 {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    p {
      text-align: center;
      font-weight: 600;
      font-size: 18px;
    }

    img {
      max-width: 100%;
    }
  }

  #facebookbanner-container {
    background: $primary-color;
    margin-bottom: 20px;
  }

  #booknowcta-container {
    background: $primary-color;
    padding: 50px 0;

    h3,
    p {
      text-align: center;
    }

    h3 {
      color: #fff;
      font-weight: 700;
      font-size: 32px;
      line-height: 2;
    }

    a {
      &:hover {
        text-decoration: none;
      }

      p {
        background: #fff;
        color: $primary-color;
        font-size: 32px;
        font-weight: 700;
        padding: 10px;
        border-radius: 10px;
        display: table;
        margin: auto;
        text-decoration: none;
      }
    }
  }
}